<template>
    <div class="page">
        <app-header :navIndex="2"></app-header>
        <section class="box">
            <div class="section-container mt-md-12 pt-md-12 mb-md-8">
                <div class="col-md-3">
                    <v-img contain :src="details.logo"></v-img>
                    <div class="logo-name text-center">{{waterList.items[0].logoName}}</div>
                </div>
            </div>

            <div class="water-box mb-md-12">
                <v-img :src="details.bg"></v-img>
                <div class="section-container inner-box d-flex flex-md-row justify-md-space-between">
                    <div class="desc">
                        {{waterList.items[0].desc}}
                    </div>
                    <div class="inner-img-box">
                        <v-img class="inner-img" :src="details.img"></v-img>
                    </div>
                </div>
            </div>
        </section>
        <section class="hidden-md-and-up">
            <div class="img-box">
                <div class="img" :style="{'background-image': 'url(' +details.bg + ')'}"></div>
                <v-img class="img1" contain :src="details.img"></v-img>
            </div>
            <div class="d-flex flex-column align-center">
                <v-img class="logo col-md-8" contain :src="details.logo"></v-img>
                <div class="logo-name mt-5 mb-md-10">{{waterList.items[0].logoName}}</div>
                <div class="desc mt-n4 mt-md-0 pa-5 pa-0" v-html="waterList.items[0].desc"></div>
            </div>
        </section>
<!--        <section class="section-container d-flex flex-column flex-md-row justify-center align-center mb-12">-->
<!--            <div class="col-10 col-md-6 pl-md-10 pa-0">-->
<!--                <v-img contain :src="water.img"></v-img>-->
<!--            </div>-->
<!--            <div class="col-md-6 pl-md-10 pr-md-10 d-flex flex-column align-center align-md-start">-->
<!--                <div class="logo col-md-8 pa-0">-->
<!--                    <v-img class="logo" contain :src="water.logo"></v-img>-->
<!--                </div>-->
<!--                <div class="logo-name mt-5 mb-md-10">{{waterList.items[1].logoName}}</div>-->
<!--                <div class="desc mt-n1 mt-md-0 pa-2 pa-md-0" v-html="waterList.items[1].desc"></div>-->
<!--            </div>-->
<!--        </section>-->
        <section class="section-container d-flex flex-column flex-md-row justify-center align-center mb-12">
            <div class="col-md-6 pl-md-10 pr-md-10 d-flex flex-column align-center align-md-start">
                <div class="logo col-md-8 pa-0">
                    <v-img class="logo" contain :src="vossWater.logo"></v-img>
                </div>
                <div class="logo-name mt-5 mb-md-10">{{waterList.items[2].logoName}}</div>
                <div class="desc mt-n1 mt-md-0 pa-2 pa-md-0" v-html="waterList.items[2].desc"></div>
            </div>
            <div class="col-10 col-md-6 pl-md-10 pa-0">
                <v-img contain :src="vossWater.img"></v-img>
            </div>
        </section>
        <section class="section-container d-flex flex-column flex-md-row justify-center align-center mb-12">
            <div class="col-md-6 pl-md-10 pr-md-10 d-flex flex-column align-center align-md-start">
                <div class="logo col-md-8 pa-0">
                    <v-img class="logo" contain :src="fllWater.logo"></v-img>
                </div>
                <div class="logo-name mt-5 mb-md-10">{{waterList.items[3].logoName}}</div>
                <div class="desc mt-n1 mt-md-0 pa-2 pa-md-0" v-html="waterList.items[3].desc"></div>
            </div>
            <div class="col-10 col-md-6 pl-md-10 pa-0">
                <v-img contain :src="fllWater.img"></v-img>
            </div>
        </section>
    </div>
</template>
<script>
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {AppHeader},
        data(){
            return {
                details: {
                    bg: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/product/water/bg.jpg'),
                    logo: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/product/water/logo.png'),
                    img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/product/water/img_one.png'),
//                    logoName: this.$i18n.t('others.water.logoName'),
//                    desc: this.$i18n.t('others.water.desc')
                },
                waterList: this.$i18n.t('others.water'),
                water: {
                    logo: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/product/water/logo_one.jpg',
                    img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/product/water/img_two.jpg',
//                    logoName: '翡莱利含气天然矿泉水',
//                    logoName: this.$i18n.t('others.water.logoName1'),
//                    desc: this.$i18n.t('others.water.desc1')
                },
                vossWater: {
                    logo: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/product/water/logo_three.jpg',
                    img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/product/water/img_three.jpg',
                },
                fllWater: {
                    logo: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/product/water/fll_logo.png',
                    img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/product/water/fll_banner.png',
                }
            }
        },
        mounted() {
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "矿泉水"
            document.getElementById("bodyId").appendChild(div);
        }
    }
</script>
<style lang="scss" scoped>
    .logo-name {
        font-size: 38px;
    }

    .water-box {
        position: relative;
        .inner-box {
            height: calc(100%);
            @include lb();
            top: 0;
            /* position: absolute;
             top: -150px;
             left: 50%;
             transform: translateX(-50%);*/
            .desc {
                width: 500px;
                /*height: 500px;*/
                height: 100%;
                font-size: 14px;
                padding: 35px 90px 35px 70px;
                background-color: rgba(255, 255, 255, 0.95);
                margin-right: 200px;
                /*margin-top: 150px;*/
            }
            .inner-img-box {
                position: relative;
                width: 500px;
                .inner-img {
                    position: absolute;
                    bottom: -20px;
                }
            }
        }
    }

    @media (max-width: $screen-md) {
        .box {
            display: none;
        }
        .img-box {
            position: relative;
            height: 300px;
            margin-top: 80px;
            margin-bottom: 40px;
            .img {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
            .img1 {
                @include lb();
                height: 365px;
                bottom: -15px;
            }
        }

        .logo {
            height: 70px;
        }
        .logo-name {
            font-size: 20px;
            color: $primary-color;
        }
    }

    @media (max-width: $screen-sm) {

    }
</style>
